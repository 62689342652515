import "./Footerstyle.css";
import React from 'react';
import Logo2 from "../assets/Logo2.png";
import { Link } from "react-router-dom";




const Footer = () => {
  return (
    <div className="footer">
        <div className="f-container">
            <div className="f-about">
                <img className="Logo2" src={Logo2} alt="Logo2"/>
                <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.</p>
            </div>
            <div className="f-bottom">
                <div className="f-links">
                    <span>لینک های پر کاربرد</span>
                    <ul>
                        <li>
                            <Link to="/">صفحه‌اصلی</Link>
                        </li>
                        <li>
                            <Link to="/Products">محصولات</Link>
                        </li>
                        <li>
                            <Link to="/Blog">وبلاگ</Link>
                        </li>
                        <li>
                            <Link to="/Contact">تماس باما</Link>
                        </li>
                    </ul>
                </div>
            
                <div className="f-contact">
                    <span>آدرس و تلفن تماس</span>
                    <p>تهران، شهرک صنعتی فلان ...</p>
                    <a href="tel:+989107218941">
                        تلفن تماس: 09107218941
                    </a>
                </div>
            </div>
        </div>
        <div className="copyright">
            <p>طراحی و سئو توسط: <a href="https://Rojax.ir/" rel="nofollow">روژاکس</a></p>
        </div>
    </div>
  )
};

export default Footer;
