import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/header';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <div>
      <Navbar />
      <Header heading="اطلاعات تماس" breadcrumb="تماس باما"/>
      <Footer />
    </div>
  )
};

export default Contact;
