import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/header';
import Footer from '../components/Footer';
import Product from '../components/Product';

const Products = () => {
  return (
    <div>
      <Navbar />
      <Header heading="محصولات آرمیک آی تی ام" breadcrumb="محصولات"/>
      <Product />
      <Footer />

    </div>
  )
};

export default Products;
