import { Link } from "react-router-dom";
import "./Homeheaderstyle.css";
import React from 'react';
import Logo from "../assets/Logo.svg";

const Homeheader = () => {
  return (
    <div className="homeheader">
        <div className="content">
            <h1>شرکت آرمیک آی تی ام</h1>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</p>
            <Link to="/products" className="btn">مشاهده محصولات</Link>
        </div>
        <div className="header-logo">
            <div className="logo-fluid">
                <img className="Logo" src={Logo} alt="Logo"/>
            </div>
        </div>

    </div>
  )
}

export default Homeheader
