import pro1 from "../assets/products/product1.jpg";


const ProductCardData = [
    {
        imgsrc: pro1,
        title: "فر پیتزا ریلی",
        text: "دستگاه Conveyor Oven خیلی خوبه به مولا",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "فرایر مرغ سوخاری",
        text: "مرغارو جزغاله میکنه جون ط",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "فرایر آسانسوری",
        text: "مجهز به 6 طبقه در برج شاهنشاهی",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "سرخ کن سیبزمینی",
        text: "سیبزمینی درسته بهش میدی خلالی سرخ شده تحویلت میده",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "سرخ کن سیبزمینی",
        text: "سیبزمینی درسته بهش میدی خلالی سرخ شده تحویلت میده",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "سرخ کن سیبزمینی",
        text: "سیبزمینی درسته بهش میدی خلالی سرخ شده تحویلت میده",
        url: "https://rojax.ir/"
    },
    {
        imgsrc: pro1,
        title: "سرخ کن سیبزمینی",
        text: "سیبزمینی درسته بهش میدی خلالی سرخ شده تحویلت میده",
        url: "https://rojax.ir/"
    },
    
];

export default ProductCardData;