import "./ProductCardStyle.css";
import ProductCard from "./ProductCard";
import ProductCardData from "./ProductsData";

import React from 'react';



const Product = () => {
  return (
    <div className="product-container">
        {ProductCardData.map((val, ind) =>{
            return(
                <ProductCard
                    key={ind}
                    imgsrc={val.imgsrc}
                    title={val.title}
                    text={val.text}
                    url={val.url}
                />
            )
        })}
    </div>
  )
};

export default Product;
