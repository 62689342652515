import { Link } from "react-router-dom";
import "./Navbarstyle.css";
import React, { useState } from 'react';
import { FaBars, FaPhoneAlt, FaTimes } from "react-icons/fa";

const Navbar = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);
    const changeColor = () =>{
        if(window.scrollY >= 100){
            setColor(true);
        }else{
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);


  return (
    <div className={color ? "top-header top-header-bg" : "top-header"}>

        <Link to="/">
            <span>آرمیک آی تی ام</span>
        </Link>
        
        <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
                <Link to="/">صفحه‌اصلی</Link>
            </li>
            <li>
                <Link to="/Products">محصولات</Link>
            </li>
            <li>
                <Link to="/Blog">وبلاگ</Link>
            </li>
            <li>
                <Link to="/Contact">تماس باما</Link>
            </li>
            
        </ul>
        <div className="hamburger" onClick={handleClick}>
            {click ? (<FaTimes size={30} style={{ color:"#fff" }} />) : (<FaBars size={30} style={{ color:"#060606" }} />)}
            
            
        </div>

        <div className="headsocials">
            <a href="tel:+989107218941"><FaPhoneAlt size={30} style={{ color:"#060606" }}/></a>
        </div>

    </div>
  )
}

export default Navbar;
