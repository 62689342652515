import { Link } from "react-router-dom";
import "./Headerstyle.css";
import React, { Component } from 'react';
import { FaAngleLeft } from "react-icons/fa";

class header extends Component {
    render(){
        return (
            <div className="header">
                <div className="heading">
                    <h1>{this.props.heading}</h1>
                    <ul>
                        <Link to="/">
                            <li>صفحه اصلی</li>
                        </Link>
                        <FaAngleLeft size={16} style={{ color:"#000" }}/>
                        <li className="active-breadcrumb">{this.props.breadcrumb}</li>
                    </ul>
                </div>
            </div>
        )
    }
};

export default header;
