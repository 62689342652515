import "./ProductCardStyle.css";
import React from 'react';
import { NavLink } from "react-router-dom";


const ProductCard = (props) => {
  return (
    <div className="product-card">
        <img src={props.imgsrc} alt="product"/>
        <div className="product-details">

            <h3>{props.title}</h3>
            <p>{props.text}</p>
            <div className="card-btns">
                <NavLink to={props.url} className="btn">مشاهده محصول</NavLink>
            </div>

        </div>
    </div>
  )
};

export default ProductCard;
