import React from 'react';
import Navbar from "../components/Navbar";
import Homeheader from "../components/Homeheader";
import Footer from '../components/Footer';



const Home = () => {
  return <div>
      <Navbar />
      <Homeheader />
      <Footer />
    </div>
};

export default Home;